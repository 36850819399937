<script>
export default {
  name: 'contextMenu',
  mounted () {
    // this.$refs.containerRef.addEventListener('contextmenu', this.handleContextMenu)
    // window.addEventListener('contextmenu', this.closeMenu, true)
    window.addEventListener('click', this.closeMenu, true)
  },
  beforeDestroy () {
    // this.$refs.containerRef.removeEventListener('contextmenu', this.handleContextMenu)
    // window.removeEventListener('contextmenu', this.closeMenu, true)
    window.removeEventListener('click', this.closeMenu, true)
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    more: {
      type: Object
    }
  },
  watch: {
    // 点击省略符号
    more: {
      handler (newVal) {
        this.handleContextMenu(newVal)
      }
    }
  },
  methods: {
    // 禁止页面滚动
    disableScroll () {
      window.addEventListener('touchmove', this.preventDefault, { passive: false })
    },
    // 允许页面滚动
    enableScroll () {
      window.removeEventListener('touchmove', this.preventDefault, { passive: false })
    },
    // 阻止 touchmove 事件的默认行为
    preventDefault (event) {
      event.preventDefault()
    },
    // 子传父选择相
    select (e) {
      return this.$emit('select-menu', e)
    },
    // 计算菜单位置
    handleContextMenu (e, song) {
      e.stopPropagation()
      e.preventDefault()
      console.log(e)
      this.disableScroll()
      this.showMenu = true

      const menuElement = document.querySelector('.context-menu')
      const menuHeight = menuElement.scrollHeight // 获取父菜单的总高度
      const viewportHeight = window.innerHeight
      const viewportWidth = window.innerWidth // 视口宽度
      const menuWidth = 170

      // 计算父菜单的Y轴位置，避免超出底部
      if (e.clientY + menuHeight > viewportHeight) {
        this.y = viewportHeight - menuHeight // 如果超出底部，则将菜单上移
      } else {
        this.y = e.clientY
      }

      // 计算父菜单的X轴位置，避免超出右侧
      if (e.clientX + menuWidth > viewportWidth) {
        this.x = viewportWidth - menuWidth // 如果超出右侧，则将菜单向左移动
      } else {
        this.x = e.clientX
      }
    },
    // 子菜单位置
    sonMenuStyle (e) {
      const viewportWidth = window.innerWidth
      const menuWidth = 170
      const subMenuWidth = 150

      // const subMenuElement = e.target.querySelector('.son-context')
      const subMenuHeight = e ? e.scrollHeight : 0// 获取子菜单的高度

      let left = this.x + menuWidth// 默认子菜单显示在父菜单右侧

      // 判断子菜单是否超出右边界
      if (left + subMenuWidth > viewportWidth) {
        left = this.x - subMenuWidth // 如果超出右侧，则将子菜单向左移
      }

      const viewportHeight = window.innerHeight
      let top = this.y // 默认子菜单显示在父菜单下方

      // 判断子菜单是否超出底部
      if (top + subMenuHeight > viewportHeight) {
        top = this.y - subMenuHeight // 如果超出底部，则将子菜单向上移
      }

      // 如果子菜单向上移后还是超出屏幕，放置在父菜单上方
      if (top < 0) {
        top = this.y - subMenuHeight
      }

      return {
        left: `${left}px`,
        top: `${top}px`
      }
    },
    // 子菜单显示
    sonShow (e) {
      // console.log(e)
      e.target.querySelector('.son-context').style.display = 'block'
    },
    // 隐藏子菜单
    hideSubMenu (e) {
      setTimeout(() => {
        e.target.querySelector('.son-context').style.display = 'none'
      }, 100)
    },
    // 关闭菜单
    closeMenu () {
      this.enableScroll()
      this.showMenu = false
    }
  },
  data () {
    return {
      x: 0,
      y: 0,
      showMenu: false
    }
  }
}
</script>

<template>
  <div ref="containerRef">
    <slot></slot>
    <div v-show="showMenu" class="context-menu" :style="{left: x + 'px',top: y + 'px'}">
      <ul class="context-list">
        <li class="menu"
            @click="select(item.label)"
            @mouseover="sonShow"
            @mouseleave="hideSubMenu"
            v-for="item in menu"
            :data-son="item.menu!==null"
            :key="item.label"
        >
          <i :class="item.icon"></i>
          {{ item.label }}
          <i style="" v-if="item.menu" class="el-icon-arrow-right"></i>
          <ul class="son-context" :style="sonMenuStyle()">
            <li class="son-menu"
                v-for="sonItem in item.menu"
                :key="sonItem.id"
                @click.stop="select(sonItem)"
            >{{ sonItem.name }}</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
.context-menu{
  width: 170px;
  position: fixed;
  background-color: #212830;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1000;
  box-shadow: 2px 2px 20px -5px black;
  .context-list{
    padding: 3px;
    .menu{
      cursor: pointer;
      color: #C0C4CC;
      padding: 10px 10px 10px 0;
      &:hover {
        background-color: #717171;
        transition: all .3s;
        border-radius: 3px;
      }
      i{
        margin: 0 10px;
      }
      .son-context{
        display: none;
        width: 150px;
        position: fixed;
        background-color: #303133;
        border-radius: 3px;
        box-shadow: 2px 2px 20px -5px black;
        white-space: nowrap;
        max-height: 300px;
        overflow-y: auto;
        z-index: 1000;
        &::-webkit-scrollbar {
          background-color: #0f1011;
        }
        &::-webkit-scrollbar-track {
          background-color: #303133;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #202021;
        }
        .son-menu{
          width: 100%;
          cursor: pointer;
          color: #C0C4CC;
          padding: 10px 10px 10px 10px;
          &:hover{
            background-color: #717171;
            border-radius: 3px;
          }
        }
      }
    }
  }
}
</style>
